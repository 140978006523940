'use client'

import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useEffect } from 'react'
import { z } from 'zod'
import { usePathname } from 'next/navigation'
import { usePostHog } from 'posthog-js/react'

const envSchema = z.object({
  NEXT_PUBLIC_POSTHOG_KEY: z.string().min(1, 'PostHog API key is required'),
})

const pageNameMap: Record<string, string> = {
  '/home': 'Home',
  '/about': 'About',
  '/courses': 'Courses',
  '/dashboard': 'Dashboard',
  '/profile': 'Profile',
  '/onboarding/basic-information': 'Basic Information',
  '/onboarding/school-information': 'School Information',
  '/onboarding/learning-goals': 'Learning Goals',
  '/onboarding/learning-preferences': 'Learning Preferences',
  '/onboarding/learning-barriers': 'Learning Barriers',
  '/onboarding/learning-environment': 'Learning Environment',
  '/onboarding/learning-technology': 'Learning Technology',
  '/onboarding/learning-privacy': 'Learning Privacy',
  '/onboarding/learning-communication': 'Learning Communication',
  '/onboarding/learning-subscription': 'Learning Subscription',
  '/onboarding/learning-payment': 'Learning Payment',
  '/onboarding/learning-confirmation': 'Learning Confirmation',
  '/course/new': 'New Course',
  '/course/[id]': 'Course',
  '/settings': 'Settings',
  '/help': 'Help',
  '/contact': 'Contact',
  '/privacy': 'Privacy Policy',
  '/terms': 'Terms of Service',
  '/refund': 'Refund Policy',
  '/cookies': 'Cookie Policy',
  '/community': 'Community',
  '/blog': 'Blog',
  '/podcast': 'Podcast',
  '/jobs': 'Jobs',
  '/press': 'Press',
  '/events': 'Events',
} as const

const getPageName = (pathname: string): string => {
  if (pathname in pageNameMap) {
    return pageNameMap[pathname as keyof typeof pageNameMap]
  }

  if (pathname.startsWith('/study/quiz/')) return 'Quiz Study'
  if (pathname.startsWith('/study/flashcard/')) return 'Flashcard Study'
  if (pathname.startsWith('/study/')) return 'Study'
  if (pathname.startsWith('/course/')) return 'Course Details'
  if (pathname.startsWith('/onboarding/')) return 'Onboarding'

  return 'Unknown Page'
}

interface CSPostHogProviderProps {
  children: React.ReactNode
}

export function CSPostHogProvider({ children }: CSPostHogProviderProps) {
  const pathname = usePathname()
  const posthogInstance = usePostHog()
  const pageName = getPageName(pathname)

  useEffect(() => {
    try {
      const { NEXT_PUBLIC_POSTHOG_KEY } = envSchema.parse({
        NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
      })

      posthog.init(NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: "/ingest",
        ui_host: "https://us.posthog.com",
        capture_pageview: true,
        capture_pageleave: true,
        person_profiles: 'always',
      })
    } catch (error) {
      console.error('PostHog initialization failed:', error)
    }
  }, [])

  useEffect(() => {
    if (!pathname || !posthogInstance) return

    const url = window.location.href;

    posthogInstance.capture('$pageview', {
      $current_url: new URL(url).pathname,
      page_name: pageName,
    })
  }, [pathname, posthogInstance, pageName])

  return <PostHogProvider client={posthogInstance}>{children}</PostHogProvider>
}
