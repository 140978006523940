import { QueryClientProvider } from "@tanstack/react-query";
import { useGlobalStore } from "@/provider/GlobalStoreProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactQueryStreamedHydration } from "@tanstack/react-query-next-experimental";
import React from "react";

interface QueryProviderProps {
  children: React.ReactNode;
}

export const QueryProvider = React.memo(function QueryProvider({
  children,
}: QueryProviderProps) {
  const { queryClient } = useGlobalStore((s) => s.global);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryStreamedHydration>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
      </ReactQueryStreamedHydration>
    </QueryClientProvider>
  );
});

QueryProvider.displayName = "QueryProvider";
