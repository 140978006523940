"use client";

import type React from "react";
import { GlobalStoreProvider } from "./GlobalStoreProvider";
import { QueryProvider } from "./QueryProvider";
import { CSPostHogProvider } from "../provider/PostHogProvider";


export default function GlobalContextProvider({
  children,
}: React.PropsWithChildren) {
  return (
    <CSPostHogProvider>
      <GlobalStoreProvider>
        <QueryProvider>{children}</QueryProvider>
      </GlobalStoreProvider>
    </CSPostHogProvider>
  );
}
